//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src

import { getFocus } from '../../../mixins/getFocus'

export default {
  name: 'Mentions',
  mixins: [getFocus],
  components: {
    ColorPointsHeader: () => import('../../../components/ColorPointsHeader')
  },
  data () {
    return {
      greenHeaderMessage: {
        titre: '',
        sousTitre: ''
      },
      focus: []
    }
  },
  async beforeMount () {
    await this.getFocus('CG Courtage', 'Mentions Légales')
      .then(res => {
        this.focus = res
        this.greenHeaderMessage.titre = this.focus.cartouche ? this.focus.cartouche.titre : ''
        this.greenHeaderMessage.sousTitre = this.focus.cartouche ? this.focus.cartouche.texte : ''
        delete this.focus.cartouche
      })
  }
}
